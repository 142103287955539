<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex;justify-content: center;">
            <span>
                {{ $t('registration.counter') }}:{{ this.storeName }}
            </span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form :onChange="saveInfo()">
                <van-field v-model="info.title">
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item v-model="info.title"
                                :title="titleOption.find(i => i.value == info.title)?.text || $t('registration.formValueTitle')"
                                :options="titleOption">
                            </van-dropdown-item>
                        </van-dropdown-menu>
                    </template>
                </van-field>
                <van-field required v-model="info.firstName" :placeholder="this.$t('registration.formValueFirstName')"
                    :error-message="errMessageFirstName" @blur="validatorFirstName" />
                <van-field required v-model="info.lastName" :placeholder="this.$t('registration.formValueLastName')"
                    :error-message="errMessageLastName" @blur="validatorLastName" @focus="validatorFirstName" />
                <van-field required :label="this.$t('registration.formValueDateOfBirth')" :error-message="errMessageBirth">
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item @change="validatorFirstName(); validatorLastName()" v-model="info.birth.day"
                                :title="info.birth.day == '' ? $t('registration.formValueDay') : info.birth.day"
                                :options="dayOption" />
                            <van-dropdown-item @change="validatorFirstName(); validatorLastName()"
                                v-model="info.birth.month"
                                :title="info.birth.month == '' ? $t('registration.formValueMonth') : info.birth.month"
                                :options="monthOption" />
                            <van-dropdown-item @change="validatorFirstName(); validatorLastName()" v-model="info.birth.year"
                                :title="info.birth.year == '' ? $t('registration.formValueYear') : info.birth.year"
                                :options="yearOption" />
                        </van-dropdown-menu>
                    </template>
                </van-field>

                <van-field class="age" :label="this.$t('registration.formValueRace')">
                    <template #input>
                        <van-radio-group v-model="info.race" direction="horizontal"
                            @change="validatorFirstName(); validatorLastName(); validatorBirth()">
                            <van-radio name="MALAY">{{ $t('registration.malay') }}</van-radio>
                            <van-radio name="CHINESE">{{ $t('registration.chinese') }}</van-radio>
                            <van-radio name="INDIAN">{{ $t('registration.indian') }}</van-radio>
                            <van-radio name="OTHERS">{{ $t('registration.other') }}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field :label="this.$t('registration.formValueMobileGender')">
                    <template #input>
                        <van-radio-group v-model="info.gender" direction="horizontal"
                            @change="validatorFirstName(); validatorLastName(); validatorBirth()">
                            <van-radio name="F">{{ $t("registration.formValueMobileGenderFemale") }}</van-radio>
                            <van-radio name="M">{{ $t("registration.formValueMobileGenderMale") }}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <div style="display: flex;align-items: center;">
                    <div style="margin-left:10px">+60</div>
                    <van-field required type="number" style="border-bottom: 1px solid #f5f6f8" maxlength="10"
                        v-model="info.mobile" :placeholder="this.$t('registration.formValueMobileNumber')"
                        :error-message="errMessagePhone"
                        @focus="validatorFirstName(); validatorLastName(); validatorBirth()" @blur="validatorPhone" />
                </div>
                <div style="display: flex;align-items: center;">
                    <div style="margin-left:10px">+60</div>
                    <van-field type="number" style="border-bottom: 1px solid #f5f6f8" maxlength="10"
                        v-model="info.othmobile" :error-message="errMessageOphone"
                        :placeholder="this.$t('registration.formValueMobileNumberoth')"
                        @focus="validatorFirstName(); validatorLastName(); validatorBirth(); validatorPhone()"
                        @blur="validatorOPhone" />
                </div>
                <van-field required v-model="info.email" :placeholder="this.$t('registration.formValueMobileEmail')"
                    :error-message="errMessageEmail"
                    @focus="validatorFirstName(); validatorLastName(); validatorBirth(); validatorPhone(); validatorOPhone()"
                    @blur="validatorEmail" />
                <!-- Subscribe all statement -->
                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions1"
                            @change="clickAgree()" />
                    </div>
                    <div class="agreeStatement">
                        {{ $t("registration.newSub1") }}{{ brandConfig.display }}{{ $t("registration.newSub2") }}{{
                            $t("registration.period1") }}
                        <a :href="brandConfig.privacyPolicy">{{ $t("registration.privacyPolicy") }}</a>
                        {{ $t("registration.period") }}
                    </div>
                </div>

                <!-- Statement Under Gender -->
                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions3" />
                    </div>
                    <div class="agreeStatement">
                        {{ $t("registration.statementUnderGender1") }}{{ brandConfig.display }}{{
                            $t("registration.possessive") }}
                        <a :href="brandConfig.privacyPolicy">{{ $t("registration.privacyPolicy") }}</a>
                        {{ $t("registration.period") }}
                    </div>
                </div>

                <!-- Statement button  -->
                <div class="second-tips" v-if="brandConfig.termsAndConditions != ''">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions" />
                    </div>
                    <div class="agreeStatement">
                        {{ $t("registration.statementButton1") }}
                        <a :href="brandConfig.termsAndConditions">{{ brandConfig.display }}
                            {{ $t("registration.loyaltyProgram") }}</a>
                    </div>

                </div>

                <div style="margin-top: 20px">

                    <van-button :disabled="!(info.agreeConditions3)" size="large" round :loading="bload"
                        class="button-submit" @click="submit()">
                        {{ $t("registration.submit") }}
                    </van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Button, Cell, CellGroup, DropdownItem, DropdownMenu,
    Field, Switch, Checkbox, CheckboxGroup, Form, Radio, RadioGroup, DatetimePicker, Popup, Dialog
} from 'vant';
import { relateAddOrUpdate } from '@/api/eformApi';
import mixins from "./../mixin/Registration.js";

export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component
    },
    props: {
    },
    created() {
        this.init()
    },
    data() {
        return {
            show: true,
            firstName: '',
            info: {
                title: '',
                firstName: '',
                lastName: '',
                birth: {
                    day: '',
                    month: '',
                    year: ''
                },
                mobile: '',
                othmobile: '',
                email: '',
                gender: '',
                age: '',
                race: '',
                // address: '',
                // address2: '',
                // city: '',
                // state: '',
                // postCode: '',
                subscribeAll: false,
                subscribeEmail: false,
                subscribeMessage: false,
                subscribeCall: false,
                subscribePost: false,
                agreeConditions: false,
                agreeSubscribeStatement: false,
                agreeCondition1: false,
                agreeCondition2: false,
                agreeCondition3: false,
                noneUser: this.$route.params.secondTitle,
                myPattern: /^$|^([0-9]{10})+$/,
                languageCode: ''
            },
            dayOption: [
                { text: '01', value: '01' },
                { text: '02', value: '02' },
                { text: '03', value: '03' },
                { text: '04', value: '04' },
                { text: '05', value: '05' },
                { text: '06', value: '06' },
                { text: '07', value: '07' },
                { text: '08', value: '08' },
                { text: '09', value: '09' },
                { text: '10', value: '10' },
                { text: '11', value: '11' },
                { text: '12', value: '12' },
                { text: '13', value: '13' },
                { text: '14', value: '14' },
                { text: '15', value: '15' },
                { text: '16', value: '16' },
                { text: '17', value: '17' },
                { text: '18', value: '18' },
                { text: '19', value: '19' },
                { text: '20', value: '20' },
                { text: '21', value: '21' },
                { text: '22', value: '22' },
                { text: '23', value: '23' },
                { text: '24', value: '24' },
                { text: '25', value: '25' },
                { text: '26', value: '26' },
                { text: '27', value: '27' },
                { text: '28', value: '28' },
                { text: '29', value: '29' },
                { text: '30', value: '30' },
                { text: '31', value: '31' }
            ],
            monthOption: [
                { text: '01', value: '01' },
                { text: '02', value: '02' },
                { text: '03', value: '03' },
                { text: '04', value: '04' },
                { text: '05', value: '05' },
                { text: '06', value: '06' },
                { text: '07', value: '07' },
                { text: '08', value: '08' },
                { text: '09', value: '09' },
                { text: '10', value: '10' },
                { text: '11', value: '11' },
                { text: '12', value: '12' }
            ],
            yearOption: [
            ],
            firstNameParten: /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/,
            errMessageFirstName: '',
            errMessageLastName: '',
            errMessageEmail: '',
            errMessagePhone: '',
            errMessageOphone: '',
            errMessageBirth: '',
            secondTitlehidden: false,
            stateList: [
                { text: 'Johor', value: 'Johor' },
                { text: 'Kedah', value: 'Kedah' },
                { text: 'Kelantan', value: 'Kelantan' },
                { text: 'Melaka', value: 'Melaka' },
                { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
                { text: 'Pahang', value: 'Pahang' },
                { text: 'Pulau Pinang', value: 'Pulau Pinang' },
                { text: 'Perak', value: 'Perak' },
                { text: 'Perlis', value: 'Perlis' },
                { text: 'Selangor', value: 'Selangor' },
                { text: 'Terengganu', value: 'Terengganu' },
                { text: 'Sabah', value: 'Sabah' },
                { text: 'Sarawak', value: 'Sarawak' },
                { text: 'Wilayah Persekutuan Kuala Lumpur', value: 'Wilayah Persekutuan Kuala Lumpur' },
                { text: 'Wilayah Persekutuan Labuan', value: 'Wilayah Persekutuan Labuan' },
                { text: 'Wilayah Persekutuan Putra Jaya', value: 'Wilayah Persekutuan Putra Jaya' },
            ],
            dropd: '',
            bload: false
        };
    },
    computed: {
        titleOption() {
            return [
                { text: this.$t('registration.ms'), value: 'MS' },
                { text: this.$t('registration.mrs'), value: 'MRS' },
                { text: this.$t('registration.mr'), value: 'MR' },
                { text: this.$t('registration.dr'), value: 'DR' },
                { text: this.$t('registration.othe'), value: 'Other' }
            ]
        },
    },
    watch: {
        'info.subscribeCall': function () {
            if (!this.info.subscribeCall) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribeEmail': function () {
            if (!this.info.subscribeEmail) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribeMessage': function () {
            if (!this.info.subscribeMessage) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        'info.subscribePost': function () {
            if (!this.info.subscribePost) {
                this.info.subscribeAll = false
            }
            if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage && this.info.subscribePost) {
                this.info.subscribeAll = true
            }
        },
        '$route': 'init',
    },
    methods: {
        init() {
            if (localStorage.getItem('info')) {
                this.info = JSON.parse(localStorage.getItem('info'))
            }
            this.getYear()
        },
        getYear() {
            this.yearOption = []
            var year = new Date().getFullYear() - 18;
            for (var i = 0; i < 100; i++) {
                this.yearOption.push({ text: (year - i).toString(), value: (year - i).toString() })
            }
        },
        validatorFirstName() {
            if (this.info.firstName == '' || this.info.firstName.trim() == '') {
                this.errMessageFirstName = this.$t('registration.rulerFirstname');
            } else {
                this.errMessageFirstName = ''
            }
        },
        validatorLastName() {
            if (this.info.lastName == '' || this.info.lastName.trim() == '') {
                this.errMessageLastName = this.$t('registration.rulerLastname');
            } else {
                this.errMessageLastName = ''
            }
        },
        validatorPhone() {
            // var num = this.info.mobile.length
            // if(num>=10){
            //     this.info.mobile = this.info.mobile.slice(0,10)
            // }
            if (this.info.mobile == '' || this.info.mobile.trim() == '') {
                this.errMessagePhone = this.$t('registration.mobileAlert');
            } else {
                var mobileF = this.info.mobile.substr(0, 1)
                if (mobileF != '1') {
                    this.errMessagePhone = this.$t('registration.mobileAlert')
                } else {
                    this.errMessagePhone = ''
                }
            }
        },
        validatorOPhone() {
            var num = this.info.othmobile.length
            if (num >= 10) {
                this.info.othmobile = this.info.othmobile.slice(0, 10)
            }
            if (this.info.othmobile != null && this.info.othmobile != '') {
                var othermobileF = this.info.othmobile.substr(0, 1)
                if (othermobileF == '0') {
                    this.errMessageOphone = this.$t('registration.otherMobileAlert')
                }
            }
        },
        validatorEmail() {
            const reg = /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/;
            if (!reg.test(this.info.email) || this.info.email == '' || this.info.email.trim() == '') {
                this.errMessageEmail = this.$t('registration.rulerEmail');
                return false;
            } else {
                this.errMessageEmail = '';
                return true;
            }
        },
        validatorBirth() {
            if (this.info.birth.day == '' || this.info.birth.month == '' || this.info.birth.year == '') {
                this.errMessageBirth = this.$t('registration.rulerBirth');
            } else {
                this.errMessageBirth = '';
                return true;
            }
        },
        submit() {
            console.log(this.info)
            this.validatorFirstName()
            this.validatorLastName()
            this.validatorBirth()
            this.validatorPhone()
            this.validatorEmail()
            if (this.errMessageBirth != '' || this.errMessageEmail != '' || this.errMessageFirstName != '' || this.errMessageLastName != '' || this.errMessagePhone != '') {
                return
            }

            this.bload = true
            relateAddOrUpdate({
                ...this.info,
                storeId: this.storeId,
                brand: this.$route.params.brand,
                country: this.$route.params.countryPath,
                title: this.info.title === 'Other' ? '' : this.info.title
            }).then(res => {
                this.bload = false
                if (res.success) {
                    if (res.data == 1) {
                        Dialog.alert({
                            message: this.$t('registration.alreadyRP'),
                            confirmButtonText: this.$t('registration.confirm')
                        })
                    } else if (res.data == 2) {
                        Dialog.alert({
                            message: this.$t('registration.alreadyRE'),
                            confirmButtonText: this.$t('registration.confirm')
                        })
                    } else {
                        console.log('跳转code' + res.data)
                        this.$router.push({
                            name: 'QRcodes',
                            params: {
                                custId: res.data,
                                storeId: this.storeId,
                                lang: this.$route.params.lang,
                                brand: this.$route.params.brand,
                                country: this.$route.params.countryPath
                            },
                            query: {
                                storeId: this.$route.query.storeId,
                            }
                        })
                    }
                }
            })
            // }).catch(() => {})
        },
        saveInfo() {
            localStorage.setItem('info', JSON.stringify(this.info))
        },
        allClick() {
            this.info.subscribeEmail = this.info.subscribeAll
            this.info.subscribeMessage = this.info.subscribeAll
            this.info.subscribeCall = this.info.subscribeAll
            this.info.subscribePost = this.info.subscribeAll
        },
        clickAgree() {
            if (this.info.agreeConditions1 == false && this.info.agreeConditions2 == false) {
                this.info.subscribeAll = false
                this.info.subscribeEmail = false
                this.info.subscribeMessage = false
                this.info.subscribeCall = false
                this.info.subscribePost = false
            }
        }
    }
}
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%
}

.agreeStatement {
    margin-left: 10px;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 20px;
    line-height: 20px;
    width: 100%;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

/*下拉框样式*/
>>>.van-dropdown-menu {
    width: 100%;
}

>>>.van-dropdown-menu__title {
    /* position: unset;
        color: #afafaf; */
    font-size: 14px;
}

>>>.van-dropdown-menu__item {
    justify-content: left;
}

>>>.van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>>.van-dialog {
    border-radius: 5px;
}

/*>>> .van-popup--top {*/
/*    width: 90%;*/
/*    margin: 0 5%;*/
/*}*/
/*>>> .van-dropdown-item__option {*/
/*    margin-left: 10px;*/
/*}*/
>>>.van-checkbox {
    /* margin-top: -10% */
}
</style>

<style lang="less" scoped>
.age .van-radio--horizontal {
    margin-bottom: 5px !important;
}

/deep/ .item1::before {
    position: absolute;
    left: -4px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}

/deep/ .item2::before {
    position: absolute;
    left: 33%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}
</style>
